<template>
  <v-card>
    <v-card-title>
      <v-text-field v-if="editTitle" v-model="sectionTitle" label="Section Title" :disabled="!sectionShowTitle" hide-details @blur="editTitle = false"></v-text-field>
      <span v-else-if="showTitle">{{ sectionTitle }}</span>
      <v-spacer></v-spacer>
      <v-menu>
        <template v-slot:activator="{ on: menuOn }">
          <v-tooltip top>
            <template v-slot:activator="{ on: tooltipOn }">
              <v-btn v-on="{ ...menuOn, ...tooltipOn }" icon>
                <v-icon>far fa-cog</v-icon>
              </v-btn>
            </template>
            <span>Section Settings</span>
          </v-tooltip>
        </template>
        <v-list dense>
          <v-list-item @click="editTitle = true">
            <v-list-item-title>Edit Section Title</v-list-item-title>
          </v-list-item>
          <v-list-item @click="$emit('change', { showTitle: !showTitle })">
            <v-list-item-title>{{ showTitle ? 'Hide' : 'Show' }} Title Text</v-list-item-title>
          </v-list-item>
          <v-list-item @click="$emit('removeSection')">
            <v-list-item-title>Remove Section</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col v-for="(input, inputIndex) in inputs" :key="'input-' + (input._id || input.label.replace(' ', '_'))" cols="12" :md="input.md" :lg="input.lg">
          <component
            :is="getComponent(input.input)"
            :input="input"
            :input-list="inputList"
            :first-in-row="inputWidths(inputIndex)"
            @change="(val) => update(val, inputIndex)"
            @remove="remove(inputIndex)"
            @addBefore="add(inputIndex-1)"
            @addAfter="add(inputIndex)"
            @edit="startEdit(input, inputIndex)"
          ></component>
        </v-col>
      </v-row>
      <v-btn color="success" @click="add()" style="margin-top:1em">
        <v-icon left>fas fa-plus-circle</v-icon>
        Add Input
      </v-btn>
    </v-card-text>
    <edit-dialog ref="editDialog" :input="selectedItem" :input-list="inputList" :allow-repeatable="true" :unique-enabled="false" @update="endEdit"></edit-dialog>
  </v-card>
</template>
<script>
import { ref, computed, watch } from '@vue/composition-api'
export default {
  components: {
    EditorInput: () => import('./input-min'),
    RepeatableBlock: () => import('./repeatable-block'),
    HtmlBlock: () => import('./html-block'),
    EditDialog: () => import('./editDialog')
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    showTitle: {
      type: Boolean,
      default: true
    },
    inputs: {
      type: Array,
      default: () => []
    }
  },
  setup (props, { emit }) {
    const editTitle = ref(false)
    const sectionTitle = ref(props.title)
    watch(sectionTitle, (val) => {
      emit('change', { title: val })
    })
    watch(() => props.title, (val) => {
      sectionTitle.value = val
    })

    const sectionShowTitle = ref(props.showTitle)
    watch(sectionShowTitle, (val) => {
      emit('change', { showTitle: val })
    })
    watch(() => props.showTitle, (val) => {
      sectionShowTitle.value = val
    })

    function update (obj, inputIndex) {
      let inputs = Object.assign([], props.inputs)
      let temp = Object.assign({}, inputs[inputIndex])
      if (typeof (obj) === 'string') {
        temp.label = obj
      } else {
        for (let l in obj) {
          if (obj[l] == null || obj[l] === '' || typeof (obj[l]) === 'undefined') continue
          if (l === 'ifs' && (!Array.isArray(obj[l]) || obj[l].length === 0)) continue
          temp[l] = obj[l]
        }
      }
      if (typeof (obj) === 'object' && 'input' in obj && (obj.input === 'repeatable-block' || obj.input === 'html-block' || obj.input === 'textarea')) {
        // automatically change all repeatable-block, html-block, and textarea inputs to be full-width
        temp.md = 12
        temp.lg = 12
        if (obj.input === 'repeatable-block') {
          if (!('settings' in temp)) {
            temp.settings = {
              showLabel: true,
              addButton: true,
              addButtonText: 'Add Another',
              min: 0,
              removable: true
            }
          }
        }
      }
      // console.log(obj, temp)
      inputs.splice(inputIndex, 1, temp)
      emit('change', { inputs })
    }
    function remove (inputIndex) {
      let inputs = Object.assign([], props.inputs)
      inputs.splice(inputIndex, 1)
      emit('change', { inputs })
    }
    function add (inputIndex) {
      let inputs = Object.assign([], props.inputs)
      let obj = {
        label: 'New Field',
        input: 'text',
        md: 4,
        lg: 4,
        required: false
      }
      if (isNaN(inputIndex)) inputIndex = inputs.length - 1
      inputs.splice(inputIndex + 1, 0, obj)
      emit('change', { inputs })
    }

    // This takes the inputs prop and converts it into a list for use in visibility rules. This ignores repeatable-block, html-block, and signature-pad.
    const inputList = computed(() => {
      let arr = []
      props.inputs.forEach(({ label, input, options, optionGroup, inputs }) => {
        if (input === 'repeatable-block' || input === 'html-block' || input === 'signature-pad' || input === 'church-search') return
        if (input === 'radio' || input === 'select' || input === 'autocomplete' || input === 'combobox') {
          if (options) arr.push({ text: label, value: label, type: input, options })
          else if (optionGroup) arr.push({ text: label, value: label, type: input, optionGroup })
          else if (inputs) arr.push({ text: label, value: label, type: input, inputs })
        } else {
          arr.push({ text: label, value: label, type: input })
        }
      })
      return arr
    })

    function getComponent (input) {
      switch (input) {
        case 'repeatable-block':
        case 'html-block':
          return input
      }
      return 'editor-input'
    }

    function inputWidths (index) {
      let width = 0
      for (let i = 0; i < index; i++) {
        if (props.inputs.length >= i) {
          width += props.inputs[i].lg || 0
          if (width >= 12) width = 0
        }
      }
      if (width === 0) return true
      if (width + props.inputs[index].lg > 12) return true
      return false
    }

    const editDialog = ref(null)
    const selectedItem = ref({})
    const selectedIndex = ref(null)
    function startEdit (item, index) {
      selectedItem.value = item
      selectedIndex.value = index
      editDialog.value.openDialog()
    }
    function endEdit (val) {
      update(val, selectedIndex.value)
      selectedItem.value = {}
      selectedIndex.value = null
    }

    return {
      editTitle,
      sectionTitle,
      sectionShowTitle,
      update,
      remove,
      add,
      inputList,
      getComponent,
      inputWidths,
      editDialog,
      selectedItem,
      selectedIndex,
      startEdit,
      endEdit
    }
  }
}
</script>
